import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { lazy } from "react";
import RootLayout from "./pages/Root";
import DelaySuspense from "./components/layout/common/DelaySuspense"; // DelaySuspense 사용
import { Analytics } from "@vercel/analytics/react";
import GoogleAnalytics from "./components/layout/common/GoogleAnalytics";
import ErrorPage from "./pages/ErrorPage";


// Lazy Load (코드 스플리팅)
const HomePage = lazy(() => import("./pages/HomePage"));
const LimitedCharPage = lazy(() => import("./pages/gacha/LimitedCharPage"));
const LimitedWeaponPage = lazy(() => import("./pages/gacha/LimitedWeaponPage"));
const AlwaysCharPage = lazy(() => import("./pages/gacha/AlwaysCharPage"));
const AlwaysWeaponPage = lazy(() => import("./pages/gacha/AlwaysWeaponPage"));
const EchoPage = lazy(() => import("./pages/EchoPage"));
const EchoCalcPage = lazy(() => import("./pages/echo-calc/EchoCalcPage"));
const EchoCalcDetailPage = lazy(() =>
  import("./pages/echo-calc/EchoCalcDetailPage")
);

// 라우터 설정
const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />, // 모든 페이지의 기본 레이아웃 컴포넌트
    errorElement: <ErrorPage />,  // 라우트 내부에서 에러 발생 시 자동 실행됨
    children: [
      {
        path: "/", // Main 홈 페이지
        element: (
          <DelaySuspense delay={1200}>
            <HomePage />
          </DelaySuspense>
        ),
      },
      {
        path: "/gacha/limited-char", // 한정 캐릭터 뽑기 페이지
        element: (
          <DelaySuspense delay={1200}>
            <LimitedCharPage />
          </DelaySuspense>
        ),
      },
      {
        path: "/gacha/limited-weapon", // 한정 무기 뽑기 페이지
        element: (
          <DelaySuspense delay={1200}>
            <LimitedWeaponPage />
          </DelaySuspense>
        ),
      },
      {
        path: "/gacha/always-char", // 상시 캐릭터 뽑기 페이지
        element: (
          <DelaySuspense delay={1200}>
            <AlwaysCharPage />
          </DelaySuspense>
        ),
      },
      {
        path: "/gacha/always-weapon", // 상시 무기 뽑기 페이지
        element: (
          <DelaySuspense delay={1200}>
            <AlwaysWeaponPage />
          </DelaySuspense>
        ),
      },
      {
        path: "/echo-simul", // 에코 뽑기 페이지
        element: (
          <DelaySuspense delay={1200}>
            <EchoPage />
          </DelaySuspense>
        ),
      },
      {
        path: "/echo-calc",
        element: (
          <DelaySuspense delay={1200}>
            <EchoCalcPage />
          </DelaySuspense>
        ),
      },
      {
        path: "/echo-calc/:charName",
        element: (
          <DelaySuspense>
            <EchoCalcDetailPage />
          </DelaySuspense>
        ),
      },

    ],
  },
]);

function App() {

  return (
    <>
      <RouterProvider router={router}>
        <GoogleAnalytics />
      </RouterProvider>
      <Analytics />
    </>
  );
}

export default App;
