/**
 * NavItemsData
 *
 * - 네비게이션 메뉴 데이터
 * - 중첩된 서브메뉴(submenu)를 포함
 *
 * 구조:
 * [
 *   {
 *     title: string;  // 메뉴 제목
 *     url?: string;   // 해당 메뉴의 링크 (없으면 클릭 불가)
 *     submenu?: [     // 하위 메뉴 리스트
 *       {
 *         title: string;  // 서브메뉴 제목
 *         url?: string;   // 서브메뉴 링크
 *         submenu?: [...];  // 추가 하위 서브메뉴
 *       }
 *     ]
 *   }
 * ]
 */
const NavItemsData = [
  {
    title: "가챠시뮬",
    url: "/gacha",
    submenu: [
      {
        title: "한정가챠",
        submenu: [
          {
            title: "한정캐릭터",
            url: "/gacha/limited-char",
          },
          {
            title: "한정무기",
            url: "/gacha/limited-weapon",
          },
        ],
      },

      {
        title: "상시가챠",
        submenu: [
          {
            title: "상시캐릭터",
            url: "/gacha/always-char",
          },
          {
            title: "상시무기",
            url: "/gacha/always-weapon",
          },
        ],
      },
    ],
  },
  {
    title: "에코튜닝",
    url: "/echo-simul",
  },
  {
    title: "에코계산기",
    url: "/echo-calc",
  },


];

export default NavItemsData;
