import { Link,useRouteError   } from "react-router-dom";
import styles from "./ErrorPage.module.css";

// 잘못된 라우터 이동시 에러 페이지 출력
const ErrorPage = ({errorCode}) => {

  const routeError = useRouteError();
  // `useRouteError()`가 있다면 그 값 사용, 없으면 props로 전달된 errorCode 사용
  const status = routeError?.status || errorCode;

  const errorMessages = {
    404: "404 | 페이지를 찾을 수 없습니다.",
    403: "403 | 접근이 거부되었습니다.",
    500: "500 | 서버 오류가 발생했습니다.",
    default: "예상치 못한 오류가 발생했습니다.",
  };


  const errorMessage = errorMessages[status] || errorMessages.default;

  return (
    <div className={styles["error-page"]}>
      <p className={styles["error-page__message"]}>{errorMessage}</p>
      <Link to="/" className={styles["error-page__link"]}>
        홈으로 이동
      </Link>
    </div>
  );
};

export default ErrorPage;
